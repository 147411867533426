
if(window.self != window.top) {
    if(jQuery(document.body).hasClass('voicethreadlms')) {
        jQuery(document.body).addClass("in-iframe");
    }

}

/**
 * Open External Links in New Tab
 */
jQuery('a').each(function() {
    var a = new RegExp('/(' + window.location.host + ')|(rutgers.instructure.com)/');
    if(this.href){
        if(!a.test(this.href)) {
            jQuery(this).click(function(event) {
                event.preventDefault();
                event.stopPropagation();
                window.open(this.href, '_blank');
            });
        }
    }
 }); 

 jQuery('.collapse').on('shown.bs.collapse', function(e) {
    var $card = jQuery(this).closest('.card');
    jQuery('html,body').animate({
      scrollTop: $card.offset().top
    }, 500);
  });

